import { render, staticRenderFns } from "./EditPanel.vue?vue&type=template&id=44023524&scoped=true&v-on-clickaway=cancel&"
import script from "./EditPanel.vue?vue&type=script&lang=ts&"
export * from "./EditPanel.vue?vue&type=script&lang=ts&"
import style0 from "./EditPanel.vue?vue&type=style&index=0&id=44023524&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44023524",
  null
  
)

export default component.exports