
import {Component, Vue, Watch} from 'vue-property-decorator';
import stores from '@/stores';
import Skeleton from '@/modules/Skeleton.vue';
import SmallSkeleton from '@/modules/SmallSkeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import NevronInput from '../../../components/NevronInput.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import {expression} from 'joi';

@Component({
  components: {
    Skeleton,
    NevronHeader,
    NevronFooter,
    NevronInput,
    TableGeneric,
    SmallSkeleton,
    TableModalGeneric
  },
})

export default class DeviceGroupDetails extends Vue {
  deviceGroup: IDeviceGroup = {
    id: null,
    name: '',
    default: null,
    active: false,
    sort: 0,
    shortDescription: '',
    type: null,
    devices: [],
  };
  stores = stores;
  /**
   *
   */
  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.reload(this.$route.params.id);
  }

  onSubmitStatus() {
    if (this.deviceGroup) {
      return stores.DeviceGroups.updateItem(this.deviceGroup.id, {active: this.deviceGroup.active})
        .then((response) => {
          if(!response.success) {
            throw new Error(response.error.message)
          }
        }).catch((e: any) => {
          showToaster('danger', '', e);
          console.log(e);
        });
    }
  }

  save() {
    if (this.deviceGroup) {
      const data = this.deviceGroup;
      return stores.DeviceGroups.updateItem(this.deviceGroup.id, data)
        .then(async (response: any) => {
          if (!response.success) {
            throw new Error(response.error.message);
          }

          await this.reload(this.$route.params.id);
          // @ts-ignore
          showToaster('success', Vue.prototype.translate(this.deviceGroup.name), 'successfully updated');
        }).catch((e: any) => {
          console.log(e);
          // @ts-ignore
          showToaster('danger',  Vue.prototype.translate(this.deviceGroup.name), 'Fail to update');
        });
    }
  }

  attachDevices(items: any[]) {
    const newItems = items.filter((el: IItem) => !this.deviceIds.includes(el.id));
    this.deviceGroup.devices = this.deviceGroup.devices.concat(newItems);
  }
  get deviceIds() {
    return this.deviceGroup.devices.map((el: any) => el.id);
  }
  removeGroup() {
      return stores.DeviceGroups.deleteItem(this.deviceGroup?.id!)
        .then((response) => {
          if(!response.success) {
            throw new Error(response.error.message)
          }
          showToaster('success', Vue.prototype.translate(this.deviceGroup.name), Vue.prototype.translate('successfully-deleted'));
            this.$router.push({name: 'devices.index', query: {
                page: this.$route.query.lastpage,
                perpage: this.$route.query.perpage,
                tab: 'groups'
            }});
        })
        .catch((e) => {
          showToaster('danger', '', e);
          console.log(e);
        });
  }

  reload(id: string) {
    return stores.DeviceGroups.editGroup(Number(id))
      .then((response:any) => {
        this.deviceGroup = response;
        console.log(this.deviceGroup,'this.deviceGroup');
      })
      .catch((e) => {
        console.log(e);
      });
  }
  detachDevice(item: IItem) {
    this.deviceGroup.devices = this.deviceGroup.devices.filter((el: any) => el.id !== item.id);
  }
  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.reload(this.$route.params.id);

  }
}
