import { render, staticRenderFns } from "./Sources.vue?vue&type=template&id=01a569a4&v-on-clickaway=cancel&"
import script from "./Sources.vue?vue&type=script&lang=ts&"
export * from "./Sources.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports