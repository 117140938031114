

import Vue from 'vue';
import {Component, Prop, Watch} from "vue-property-decorator";
// @ts-ignore
import Switches from 'vue-switches';
// @ts-ignore
import VueQRCodeComponent from 'vue-qrcode-component';
import {mixin as Clickaway} from "vue-clickaway";
import routerBase from '@/helpers/routerBase';
import stores from '@/stores';
import session from '../stores/Session';
import {showToaster} from '@/components/DashboardComposer/helpers';

@Component({
  components: {
    qrCode: VueQRCodeComponent,
    Switches
  },
  mixins: [Clickaway],
})
export default class NevronModuleQr extends Vue {
  @Prop()
  moduleId: any;

  @Prop({default: null})
  module: any;

  @Prop()
  type: any;

  size: any = 400;

  moduleQr: any = null;
  pwaURLSetting!: ISetting;
  baseURL: string = '';

  urlContent: string = ''
  qrAttributes: any = {};

  get qrUrl() {
    return session.project?.qrMapperUrl + '/' + this.module.qr?.id
  }

  get getType() {
    return this.type == 'DYNAMIC'?0:1;
  }

  initQRAttributes() {
    return {
      mlAppDownload: {
        moduleId: this.moduleId,
        key: 'ml_app_download',
        value: false,
        queryText: 'app_download',
        type: this.getType
      },
      mlAppleAppIdentifier: {
        moduleId: this.moduleId,
        key: 'ml_apple_app_identifier',
        value: '',
        queryText: 'apple_app_identifier',
        type: this.getType
      },
      mlAndriodAppIdentifier: {
        moduleId: this.moduleId,
        key: 'ml_andriod_app_identifier',
        value: '',
        queryText: 'android_app_identifier',
        type: this.getType
      },
      mlUserLoginRequired: {
        moduleId: this.moduleId,
        key: 'ml_user_login_required',
        value: false,
        queryText: 'user_login_required',
        type: this.getType
      },
      mlLinkToAppStore: {
        moduleId: this.moduleId,
        key: 'ml_link_to_app_store',
        value: '',
        queryText: 'link_app_store',
        type: this.getType
      },
      mlLinkToPlayStore: {
        moduleId: this.moduleId,
        key: 'ml_link_to_play_store',
        value: '',
        queryText: 'link_play_store',
        type: this.getType
      },
    };
  }

  mounted() {
    stores.Setting.getSettings()
      .then((response) => {
        let pwaSetting = response.filter((item: any) => item.key === 'pwa_url')
        if(pwaSetting[0]) {
          this.baseURL = pwaSetting[0].value;
        }
      }).then(() => {
        this.baseURL += `/${routerBase}?tenantName=${routerBase}&moduleType=${this.type === 'DYNAMIC'?'dynamic': 'guestflow'}
        &moduleId=${this.type==='DYNAMIC'?this.moduleId:''}&elementId=${this.type === 'DYNAMIC'?'':this.moduleId}`;

        this.qrAttributes = this.initQRAttributes();
        this.showUrl();
        this.moduleWatch();
    });

  }

  saveQrImage() {
    try {
      // @ts-ignore
      const figure = document.querySelector('#qrcode img').getAttribute('src');
      const a = document.createElement('a'); // Create <a>
      // @ts-ignore
      a.href = figure;
      a.download = `${Vue.prototype.translate(this.module.name)}.png`; // File name Here
      a.click(); // Downloaded file
      showToaster('success', Vue.prototype.translate('qr-code'), Vue.prototype.translate('successfully-downloaded'));
    } catch (e) {
      showToaster('danger', Vue.prototype.translate('qr-code'), Vue.prototype.translate('downloading-failed'));
    }
  }

  copyQRURL() {
    navigator.clipboard.writeText(this.qrUrl)
      .then(() => {
        showToaster('success', Vue.prototype.translate('qr-code'), Vue.prototype.translate('url-copied'));
      })
      .catch((error) => {
        showToaster('danger', Vue.prototype.translate('qr-code'), Vue.prototype.translate('failed-to-copy'));
      });
  }

  showUrl() {
    this.urlContent = this.baseURL;
    for (const index in this.qrAttributes) {
      if (index && this.module.qrAttributes[index]) {
        this.urlContent += `&${this.qrAttributes[index].queryText}=${this.module.qrAttributes[index].value}`;
      }
    }
    this.urlContent = this.urlContent.replace(/\s/g, "");
    this.module.url = this.urlContent;
  }

  @Watch('module')
  moduleWatch() {
    console.log('module watcher', this.module.qrAttributes);
    if (!this.module.qrAttributes || this.module.qrAttributes.length <= 0) {
      this.module.qrAttributes = this.qrAttributes;
    }
    if( Reflect.ownKeys(this.module.qrAttributes).length <= 6){
      for (const index in this.qrAttributes) {
        if (!this.module.qrAttributes[index]) {
          this.module.qrAttributes[index] = this.qrAttributes[index];
        }
      }
    }
    if (this.module.url) {
      this.showUrl();
    }
  }

  @Watch('moduleId')
  moduleIdWatch() {
    for (const index in this.qrAttributes) {
      if (index) {
        this.qrAttributes[index].moduleId = this.moduleId;
      }
    }
  }

  @Watch('type')
  typeWatch() {
    for (const index in this.qrAttributes) {
      if (index) {
        this.qrAttributes[index].type = this.getType;
      }
    }
  }
}
