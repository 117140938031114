import { render, staticRenderFns } from "./AppendItems.vue?vue&type=template&id=773d8b10&scoped=true&v-on-clickaway=cancel&"
import script from "./AppendItems.vue?vue&type=script&lang=ts&"
export * from "./AppendItems.vue?vue&type=script&lang=ts&"
import style0 from "./AppendItems.vue?vue&type=style&index=0&id=773d8b10&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "773d8b10",
  null
  
)

export default component.exports